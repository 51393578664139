var _a;
import { experimentsService } from '@wix/cashier-common/dist/src/utils/experimentsService';
import { Experiment } from './enums/Experiment';
export var BASE_URL = 'https://cashier.wixapps.net';
export var ORDER_API_PATH = '/_api/payment-paybutton-web/paybutton/v2/orders';
export var PRODUCT_API_PATH = '/_api/payment-paybutton-web/paybutton/v2/products';
export var panelHelpId = '48d2426a-56ca-4b09-ba4b-1f709ad6debb';
export var APP_ID = 'cdd4b6d5-6fb4-4bd1-9189-791244b5361e';
export var POP_UP_LINK = 'https://cashier.wixapps.net/payment_app/premium-popup';
export var NO_PAYMENTS_POP_UP_LINK = 'https://cashier.wixapps.net/payment_app/no-payments-popup';
// TODO: Check if it's possible to replace with wixCodeApi enums
export var ViewMode;
(function (ViewMode) {
    ViewMode["PREVIEW"] = "Preview";
    ViewMode["SITE"] = "Site";
})(ViewMode || (ViewMode = {}));
// TODO: Check if it's possible to replace with wixCodeApi enums
export var UserRole;
(function (UserRole) {
    UserRole["ADMIN"] = "Admin";
})(UserRole || (UserRole = {}));
export var createDefaultProduct = function (currency, name) { return ({
    product: {
        currency: currency,
        name: name,
        price: 1,
    },
}); };
export var createSettingsPanelConfig = function (_a) {
    var instance = _a.instance, appDefinitionId = _a.appDefinitionId, helpId = _a.helpId, componentRef = _a.componentRef, productId = _a.productId, buttonLabel = _a.buttonLabel, settingsTitle = _a.settingsTitle, startLoadTime = _a.startLoadTime;
    var isBusinessPremiumPlanTextEnabled = experimentsService.isExperimentEnabled(Experiment.PayButtonBusinessPremiumPlanText);
    return {
        componentRef: componentRef,
        title: settingsTitle,
        width: 288,
        height: 546,
        helpId: helpId,
        url: "https://www.wix.com/pay-button-settings?instance=" + instance + "&isBusinessPremiumPlanTextEnabled=" + isBusinessPremiumPlanTextEnabled,
        initialData: {
            productId: productId,
            buttonLabel: buttonLabel,
            appDefinitionId: appDefinitionId,
            componentRef: componentRef,
            startLoadTime: startLoadTime,
        }
    };
};
var DeviceType;
(function (DeviceType) {
    DeviceType["Desktop"] = "Desktop";
    DeviceType["Mobile"] = "Mobile";
    DeviceType["Tablet"] = "Tablet";
})(DeviceType || (DeviceType = {}));
export var MODAL_SIZE = (_a = {},
    _a[DeviceType.Desktop] = {
        width: 750,
        height: 550,
    },
    _a[DeviceType.Tablet] = {
        width: 750,
        height: 550,
    },
    // workaround to open modal to 100%, openModal doesn't support string values
    // https://wix.slack.com/archives/CAKBA7TDH/p1604926273350300?thread_ts=1604922926.343800&cid=CAKBA7TDH
    _a[DeviceType.Mobile] = {
        width: 4000,
        height: 4000,
    },
    _a);
